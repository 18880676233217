import React, { Component } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

export default class Lightbox extends Component {
  static propTypes = {
    extraImages: PropTypes.array.isRequired,
  }

  //This whole component is a bit of a confusing mess & needs simplifying
  // rename to match large / thumb not 'extra image'

  constructor(props) {
    super(props)

    this.state = {
      showLightbox: false,
      selectedImage: null,
      index: null,
    }

    this.loadPrevImage = this.loadPrevImage.bind(this);
    this.loadNextImage = this.loadNextImage.bind(this);

  }

  loadPrevImage() {
    const { extraImages } = this.props

    if (this.state.index === 0) {
      // current image is first, set index to last image index
      const newIndex = extraImages.length - 1;
      //this.setState( {selectedImage: extraImages[newIndex]} )
      this.setState({ index: newIndex })
      // set selectedImage
      this.setState({ selectedImage: extraImages[newIndex].extraImage })
    }
    else {
      const newIndex = this.state.index - 1;
      // set index to index - 1;
      this.setState({ index: newIndex })
      // set selectedImage
      this.setState({ selectedImage: extraImages[newIndex].extraImage })
    }
  }

  loadNextImage() {
    const { extraImages } = this.props
    if (this.state.index === extraImages.length - 1) {
      // current image is last, set index to first image index
      this.setState({ index: 0 })
      this.setState({ selectedImage: extraImages[0].extraImage })
    }
    else {
      const newIndex = this.state.index + 1;
      this.setState({ index: newIndex })
      this.setState({ selectedImage: extraImages[newIndex].extraImage })
    }
  }

  render() {
    const { extraImages } = this.props
    const { showLightbox } = this.state

    return (
      <>

        <StyledExtraImagesList>
          {extraImages.map((image, index) => (
            <StyledPreviewButton
              aria-label="Show larger version of this image"
              key={image.extraImage.childImageSharp.fluid.src + index}
              type="button"
              onClick={() =>
                this.setState({
                  showLightbox: true,
                  selectedImage: image.extraImage,
                  index: index
                })
              }
            >

              <Img sizes={{ ...image.thumb.childImageSharp.fluid, aspectRatio: 4 / 4 }} />
            </StyledPreviewButton>
          ))}
        </StyledExtraImagesList>

        {showLightbox && (
          <StyledDialog   onDismiss={() => this.setState({ showLightbox: false })}>
            <StyledDialogButtons>
             
              <button aria-label="Show previous image" className="arrow prev" onClick={this.loadPrevImage}>&lt;</button>
              <button aria-label="Show next image" className="arrow next" onClick={this.loadNextImage}>&gt;</button>

            </StyledDialogButtons>

          
            <Img
              fluid={this.state.selectedImage.childImageSharp.fluid}
            />
           <button className="close"
                type="button"
                aria-label="Close large image modal"
                onClick={() => this.setState({ showLightbox: false })}
              >
                Close
            </button>

          </StyledDialog>
        )}
      </>
    )
  }
}

const StyledDialog = styled(Dialog)`
  @media (max-width:1000px){
    width:80vw !important;

  }
  position:relative;
  padding: 2rem 48px 1.5rem !important;


  .close{
      line-height:1;
      background: rgba(114,177,0,0.8);
      color: #fff;
      font-size: 1.2rem;
      border: none;
      padding: 8px 15px;
      margin-top: 1px;
 
  }
`

const StyledExtraImagesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > button {
    
  }
`

const StyledPreviewButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;

  width: calc(33.3333% - 10px);
    margin: 0 5px 10px;
`

const StyledDialogButtons = styled.div`
  .arrow{
    position:absolute;
    top:0;
    bottom:0;
    width:48px;
    z-index:2;
    font-size: 3rem;
    background-color:transparent;
    color:#888;
    border:none;
    transition:color 0.3s, background-color 0.3s;
    &:hover{
      color:#fff;
      background-color:#ccc;
    }

    
  }

  .prev{
    left:0;
    
  }

  .next{
    right:0;
  }

`


