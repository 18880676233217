//!!!!!!!!!!!!!!!!!!!!!!!!!!! muli extra light italic not loading !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import {accent} from "../utilities"
import styled from "styled-components"
//import { MuliExtraLightItalic } from "../utilities"  - was for testimonials
import Lightbox from "../components/lightbox"
import { Link } from "gatsby"
import SEO from "../components/seo"







export default ({ data }) => {
  const post = data.markdownRemark
  const metaDescription = `${post.frontmatter.type}, ${post.frontmatter.location}. ${post.frontmatter.concept}`

  //work out sibling slugs
  let nextSlug = undefined;
  let prevSlug = undefined;
  const sameSectorNodes = data.allMarkdownRemark.nodes;
  const slugs = [];
  sameSectorNodes.forEach(node => {
    slugs.push(node.fields.slug);
  });
  const currentItemSlug = data.markdownRemark.fields.slug;
  const currentItemIndex = slugs.indexOf(currentItemSlug);

  if (slugs.length >= 2 && currentItemIndex !== -1) {

    //if current item is first, set next
    if (currentItemIndex === 0) {
      nextSlug = slugs[1];
      //return;
    }

    //if current item is last, set prev
    if (currentItemIndex === slugs.length - 1) {
      prevSlug = slugs[currentItemIndex - 1];
      //return;
    }

    //otherwise it must be int the middle somewhere
    if (slugs.length > 2 && currentItemIndex !== 0 && currentItemIndex !== slugs.length - 1) {
      prevSlug = slugs[currentItemIndex - 1];
      nextSlug = slugs[currentItemIndex + 1];
    }
  }



  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={metaDescription}
      />
      <PortfolioItem>

        <div className="portfolio-item__sidebar">
          <div className="portfolio-item__main-image">
            <Img fluid={post.frontmatter.mainImage.childImageSharp.fluid} />
          </div>

          {/* this is ugly - find a better way */}
          {post.frontmatter.extraImages &&
            post.frontmatter.extraImages[0].extraImage && (
              //post.frontmatter.extraImages.map(image => <div>extra image</div>)
              <>
                <hr />


                <Lightbox extraImages={post.frontmatter.extraImages} />
              </>
            )}

          {post.frontmatter.clientTestimonial && (
            <>
              <hr />
              <div className="portfolio-item__testimonial">
                "{post.frontmatter.clientTestimonial}"
              </div>
              <div className="portfolio-item__author">
                {post.frontmatter.clientTestimonialAuthor}
              </div>
            </>
          )}
        </div>
        <div className="portfolio-item__main">
          <div className="portfolio-item__header">
            <div className="portfolio-item__header-inner">
              <h1 className="portfolio-item__title">
                <span className="portfolio-item__title-text">
                  {post.frontmatter.title}
                </span>
                <span className="portfolio-item__arrows">
                  {prevSlug &&
                    <Link className="portfolio-item__arrow" to={"/portfolio" + prevSlug}>&#8249;</Link>
                  }
                  {nextSlug &&
                    <Link className="portfolio-item__arrow" to={"/portfolio" + nextSlug}>&#8250; </Link>
                  }
                </span>

              </h1>
              {post.frontmatter.location && (
                <div className="portfolio-item__detail">
                  <strong>Location:</strong> {post.frontmatter.location}
                </div>
              )}
              {post.frontmatter.type && (
                <div className="portfolio-item__detail">
                  <strong>Type:</strong> {post.frontmatter.type}
                </div>
              )}
              {post.frontmatter.floorArea && (
                <div className="portfolio-item__detail">
                  <strong>Floor Area:</strong> {post.frontmatter.floorArea}
                </div>
              )}
              {post.frontmatter.concept && (
                <div className="portfolio-item__detail">
                  <strong>Concept:</strong> {post.frontmatter.concept}
                </div>
              )}
              {post.frontmatter.construction && (
                <div className="portfolio-item__detail">
                  <strong>Construction:</strong> {post.frontmatter.construction}
                </div>
              )}
              {post.frontmatter.status && (
                <div className="portfolio-item__detail">
                  <strong>Status:</strong> {post.frontmatter.status}
                </div>
              )}
              {post.frontmatter.photographs && (
                <div className="portfolio-item__detail">
                  <strong>Photographs:</strong> {post.frontmatter.photographs}
                </div>
              )}
              {post.frontmatter.note && (
                <div className="portfolio-item__detail">
                  <strong>Note:</strong> {post.frontmatter.note}
                </div>
              )}

              {/* {post.frontmatter.sector && (
                <div className="portfolio-item__detail">
                  <strong>Sector:</strong> {post.frontmatter.sector}
                </div>
              )} */}

            </div>
          </div>

          {post.html.length > 3 && (
            <div
              className="portfolio-item__description"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          )
          }
        </div>

      </PortfolioItem>
    </Layout>
  )
}

const PortfolioItem = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin:0 auto 50px;

  @media (min-width: 701px) {
    flex-direction: row;
    margin: 50px auto 50px;
  }

  .portfolio-item__sidebar {
    width: 100%;
    @media (min-width: 701px) {
      width: calc(50% - 20px);
    }
  }

  .portfolio-item__main-image {
    position: relative;
    margin-left: 5px;
    margin-bottom: 35px;
    background: #fff;

    .gatsby-image-wrapper {
      background: #fff;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(45deg, rgba(114, 177, 0, 0.8) 0%, #fff 100%);
      transform: translate3d(-5px, 5px, 0);
    }
  }

  .portfolio-item__main {
    width: 100%;
    margin-bottom: 30px;

    @media (min-width: 701px) {
      width: calc(50% - 20px);
      margin-bottom: 0;
    }

    strong {
      color: #000;
    }

    h1 {
      margin-top: 0;
      line-height: 1;
    }
  }

  .portfolio-item__description {
    padding: 0 20px;
  }

  .portfolio-item__detail{
    margin-bottom:10px;
  }

  .portfolio-item__header {
    position: relative;
    background: #fff;
    line-height:1.5;

    
  }

  .portfolio-item__header-inner {
    background: #fff;
    padding: 0 20px 15px 20px;
  }

  .portfolio-item__title{
    position:relative;
    padding-right:106px;
  }

  .portfolio-item__title-text{
    margin-right:10px;
  }

  .portfolio-item__testimonial {
 
  }

  .portfolio-item__author {
    margin-top: 20px;
  }

  .portfolio-item__arrows{
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
  }

  .portfolio-item__arrow{
        display:inline-block;
        width:48px;
        height: 48px;
        vertical-align: middle;
        line-height: 48px;
        text-align: center;
        font-size:3rem;
        text-decoration:none;
        margin-top: -10px;
        color: #000;

        &:hover{
          color: ${accent};
        }

    &:last-child{
      margin-left:10px;
    }
  }

  hr {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3) 0,
      rgba(0, 0, 0, 0.05) 100%
    );

    height: 2px;
    border: none;
    margin: 30px 0;
  }
`

export const PORTFOLIO_ITEM_QUERY = graphql`
  query($slug: String!, $sector: String!) {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "portfolio-item"}, sector: {eq: $sector}}}, sort: {fields: [frontmatter___displayOrder], order: [ASC]}) {
      nodes {
        fields {
          slug
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields{
        slug
      }
      frontmatter {
        title
        location
        type
        floorArea
        concept
        construction
        photographs
        note
        sector
        status
        mainImage {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        mainImageThumb: mainImage {
          childImageSharp {
            fluid(maxWidth: 160, maxHeight: 120) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        extraImages {
          extraImage {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          thumb: extraImage {
            childImageSharp {
              fluid(maxWidth: 160, maxHeight: 160) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      html
    }
  }
`

